import React from 'react'

export const PlayIconYellow = props => (
  <svg viewBox="0 0 34.9 40.8" {...props}>
    <path
      d="M16.9 25.3c-4.2 2.5-8.4 5-12.6 7.4 1.5.9 3 1.7 4.5 2.6.1-5.1.2-10.3.4-15.4.1-3.9-5.9-3.9-6 0-.1 5.1-.2 10.3-.4 15.4-.1 2.3 2.6 3.7 4.5 2.6 4.2-2.5 8.4-5 12.6-7.4 3.3-1.9.3-7.1-3-5.2z"
      fill="#f99d2a"
    />
    <path
      d="M18.5 17c3.4 2.2 6.7 4.4 10.1 6.6v-5.2l-11.7 6.9c-3.3 2-.3 7.1 3 5.2l11.7-6.9c2-1.2 1.9-4 0-5.2-3.4-2.2-6.7-4.4-10.1-6.6-3.2-2.1-6.2 3.1-3 5.2z"
      fill="#fae671"
    />
    <path
      d="M9.2 20l.3-14.4C8 6.5 6.5 7.3 5 8.2c4.5 3 9 5.9 13.5 8.9 3.2 2.1 6.2-3.1 3-5.2C17 8.9 12.5 6 8 3c-2-1.3-4.5.4-4.5 2.6L3.2 20c-.1 3.8 5.9 3.8 6 0z"
      fill="#f7b065"
    />
  </svg>
)

export const PlayIconPurple = props => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 34.9 40.8"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.prefix__st0{fill:#662d91}'}</style>
    <path
      className="prefix__st0"
      d="M17 24.8c-4.2 2.5-8.4 5-12.6 7.4 1.5.9 3 1.7 4.5 2.6.1-5.1.2-10.3.4-15.4.1-3.9-5.9-3.9-6 0-.1 5.1-.2 10.3-.4 15.4-.1 2.3 2.6 3.7 4.5 2.6 4.2-2.5 8.4-5 12.6-7.4 3.3-1.9.3-7.1-3-5.2z"
    />
    <path
      className="prefix__st0"
      d="M18.6 16.5c3.4 2.2 6.7 4.4 10.1 6.6v-5.2L17 24.8c-3.3 2-.3 7.1 3 5.2l11.7-6.9c2-1.2 1.9-4 0-5.2-3.4-2.2-6.7-4.4-10.1-6.6-3.2-2.1-6.2 3.1-3 5.2z"
      opacity={0.36}
    />
    <path
      d="M9.3 19.5l.3-14.4c-1.5.9-3 1.7-4.5 2.6 4.5 3 9 5.9 13.5 8.9 3.2 2.1 6.2-3.1 3-5.2-4.5-3-9-5.9-13.5-8.9-2-1.3-4.5.4-4.5 2.6l-.3 14.4c-.1 3.8 5.9 3.8 6 0z"
      fill="#a060a7"
    />
  </svg>
)

export const PlayIconBlue = props => (
  <svg viewBox="0 0 34.9 40.8" {...props}>
    <path
      d="M17 25.3c-4.2 2.5-8.4 5-12.6 7.4 1.5.9 3 1.7 4.5 2.6.1-5.1.2-10.3.4-15.4.1-3.9-5.9-3.9-6 0-.1 5.1-.2 10.3-.4 15.4-.1 2.3 2.6 3.7 4.5 2.6 4.2-2.5 8.4-5 12.6-7.4 3.3-1.9.3-7.1-3-5.2z"
      fill="#1b75bc"
    />
    <path
      d="M18.6 17c3.4 2.2 6.7 4.4 10.1 6.6v-5.2L17 25.3c-3.3 2-.3 7.1 3 5.2l11.7-6.9c2-1.2 1.9-4 0-5.2-3.4-2.2-6.7-4.4-10.1-6.6-3.2-2.1-6.2 3.1-3 5.2z"
      fill="#00a79d"
      opacity={0.55}
    />
    <path
      d="M9.3 20l.3-14.4c-1.5.9-3 1.7-4.5 2.6 4.5 3 9 5.9 13.5 8.9 3.2 2.1 6.2-3.1 3-5.2-4.5-3-9-5.9-13.5-8.9-2.1-1.4-4.9.7-4.4 3.2-.1 4.3-.1 8.4-.2 12.6-1.4 5 5.7 5.4 5.8 1.2z"
      fill="#318faf"
    />
  </svg>
)
