import React, { Component, createRef } from 'react'

import styled from 'styled-components'

import Layout from '../../components/layout'

import { MainSectionWrapper, PageHeader } from '../../components/common/styles'

import { PlayIconBlue, PlayIconYellow, PlayIconPurple } from '../../components/icons_and_logos/play-icons'

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    grid-row: 3 / 5;

    margin-top: 80px;

    & > p {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
`

const RecordingName = styled.div`
    align-self: flex-end;
    font-weight: bold;
    margin-bottom: 10px;
`

const AudioSection = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 100px auto;


    & > * {
        margin: 20px;
    }
`

const PlaybackLine = styled.div`
    width: 100%;
    border-bottom: 2px solid ${props => props.color};
    position: relative;
`

const PlaybackDot = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${props => props.color};
    top: -4px;
    left: ${props => props.percentageComplete};
    border-radius: 5px;
`

class Research extends Component {
    scrollRef = createRef()

    componentDidMount = () => {
        window.addEventListener('keydown', this.scroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.scroll)
    }

    scroll = (e) => {
        switch (e.key) {
            case "ArrowDown":
                this.scrollRef.current.scrollBy(0, 30)
                break
            case "ArrowUp":
                this.scrollRef.current.scrollBy(0, -30)
                break
        }
    }

    render() {
        return (
            <Layout>
                <MainSectionWrapperOverride ref={this.scrollRef}>
                    <PageHeader style={{ color: '#5399b7' }}>The world in Kichwa by the Pachamama community</PageHeader>
                    <p>
                        Kichwa is the native language spoken by the Pachamama community: artisans in the region of Ibambura, Ecuador.
                        Many of the older and wiser members tell beautiful folk stories only spoken in the language.
                        The dialect, sound and stories contained within are a beautiful experience worth sharing.
                    </p>
                    <p>
                        The language has extension in Colombia, Ecuador and Peru.
                    </p>
                    <AudioSection>
                        <AudioPlayer
                            playIcon={<PlayIconYellow />}
                            color='#f7b065'
                            source='https://s3.amazonaws.com/valentina-site/research_audio/antonio_y_micaela.m4a'
                        />
                        <RecordingName>- Antonio y Micaela</RecordingName>
                        <AudioPlayer
                            playIcon={<PlayIconBlue />}
                            color='#2f8faf'
                            source='https://s3.amazonaws.com/valentina-site/research_audio/cone_jo_manuel.m4a'
                        />
                        <RecordingName>- Gallo Diosito</RecordingName>
                        <AudioPlayer
                            playIcon={<PlayIconPurple />}
                            color='#a05fa6'
                            source='https://s3.amazonaws.com/valentina-site/research_audio/diablo_diosito.m4a'
                        />
                        <RecordingName>- Conejo Jose Manuel</RecordingName>
                    </AudioSection>
                </MainSectionWrapperOverride>
            </Layout>
        )
    }
}

class AudioPlayer extends Component {
    state = {
        percentageComplete: '0%'
    }

    audioRef = React.createRef()

    updateDotPosition = () => {
        const audioElement = this.audioRef.current
        const percentageComplete = `${audioElement.currentTime / audioElement.duration * 100}%`

        this.setState({ percentageComplete })
    }

    playAudio = () => {
        const currentAudioElement = this.audioRef.current

        if (currentAudioElement.paused) {
            const audioElements = window.document.getElementsByTagName("audio")

            for (const audioElement of audioElements) {
                audioElement.pause()
            }

            currentAudioElement.play()
        } else {
            currentAudioElement.pause()
        }
    }

    render() {
        const {
            playIcon,
            color,
            source,
        } = this.props

        const {
            percentageComplete,
        } = this.state

        return (
            <>
                <audio
                    ref={this.audioRef}
                    src={source}
                    type="audio/mp4"
                    onTimeUpdate={this.updateDotPosition}
                    onEnded={() => this.setState({ percentageComplete: '0%' })}
                />
                <PlaybackLine color={color}>
                    <PlaybackDot color={color} percentageComplete={percentageComplete} />
                </PlaybackLine>
                {React.cloneElement(
                    playIcon,
                    {
                        width: 50,
                        style: { cursor: 'pointer' },
                        onClick: this.playAudio
                    })}
            </>
        )
    }
}

export default Research